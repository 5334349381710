import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import io from 'socket.io-client'



const App = () => {
    const noteId = location.pathname.replace('/', '').toLowerCase()
    const [content, setContent] = useState('')
    const socket = useRef()
    const timeoutRef = useRef()
    const audioTimeoutRef = useRef()
    const audioRef = useRef()
    const soundAlert = useRef(false)

    let cnt = 0
    useEffect(() => {
        if (cnt++)
            return

        socket.current = io('np-back.tech2space.in')

        socket.current.on('connect', () => {
            if (!noteId) {
                socket.current.emit('get:note:new')
            } else {
                socket.current.emit('get:note:existing', { noteId })
            }
        })

        socket.current.on('r:note:new', ({ noteId }) => {
            location.assign(`/${noteId}`)
        })

        socket.current.on('r:note:existing', ({ content }) => {
            setContent(content)
        })

        socket.current.on('r:note:update', async ({ noteId, content }) => {
            setContent(content)

            if (audioTimeoutRef.current)
                clearTimeout(audioTimeoutRef.current)

            audioTimeoutRef.current = setTimeout(async () => {
                if (soundAlert.current) {
                    await audioRef.current.play()
                }
            }, 2000)
        })

    }, [])

    return (
        <div className='app'>
            <div className='header'></div>
            <div className='container'>
                <div className='left'></div>
                <div className='center'>
                    <div className='container-header'>
                        <div>Notepad - {noteId}</div>
                        <div className='d-flex' style={{ gap: 10 }}>
                            <div className='d-flex'>
                                <audio src='/notification.mp3' ref={audioRef} style={{ display: 'none' }} />
                                <label htmlFor='audio'>Notify</label> <input type='checkbox' title={'Alert on update'} id='audio' onChange={(e) => soundAlert.current = e.target.checked} />
                            </div>
                            <div>
                                <button onClick={() => location.assign('/')}>New</button>
                            </div>
                        </div>
                    </div>
                    <div className='container-textarea'>
                        <textarea placeholder='Start taking notes' value={content} onChange={(e) => {
                            const newContent = e.target.value

                            setContent(newContent)

                            if (!socket.current.connected)
                                return

                            if (timeoutRef.current)
                                clearTimeout(timeoutRef.current)

                            timeoutRef.current = setTimeout(() => {
                                if (!socket.current.connected)
                                    return

                                socket.current.emit('save:note', { noteId, content: newContent })
                            }, 200)
                        }} onKeyDown={(e) => {
                            if (e.key == 'Tab') {
                                e.preventDefault()
                                setContent(preContent => preContent + '    ')
                            }
                        }} autoFocus readOnly={!noteId} />
                    </div>
                    <div className='container-footer'>
                        Length: {content.length}
                        &nbsp;
                        Words: {content.split(/\s+/).filter(word => word.length > 0).length}
                    </div>
                </div>
                <div className='right'></div>
            </div>
            <div className='footer'></div>
        </div>
    )
}

export default App
